<template>
  <v-card
    class="sales-payments-summary"
    v-if="account"
  >
    <v-card-title>{{`${$t('t.Sales')} / ${$t('t.Payments')}`}}</v-card-title>
    <graph
      width="100%"
      height="255px"
      :options="chartOptions"
      :series="series"
    />
    <v-list class="px-4">
      <grid
        class="flex-grow-1"
        item-min-width="200px"
      >
        <v-list-item v-if="account.sales.last">
          <v-list-item-title>{{formatCurrency(account.sales.last.amount, account.currency)}}</v-list-item-title>
          <v-list-item-subtitle>{{$t('t.LastSales')}} {{moment(account.sales.last.date).fromNow()}}</v-list-item-subtitle>
        </v-list-item>
        <v-list-item v-else>
          <v-list-item-subtitle>No sales</v-list-item-subtitle>
        </v-list-item>
        <v-list-item v-if="account.payments.last">
          <v-list-item-title>{{formatCurrency(account.payments.last.amount, account.currency)}}</v-list-item-title>
          <v-list-item-subtitle>{{$t('t.LastPayment')}} {{moment(account.payments.last.date).fromNow()}}</v-list-item-subtitle>
        </v-list-item>
        <v-list-item v-else>
          <v-list-item-subtitle>{{$t('t.NoPaymentReceived')}}</v-list-item-subtitle>
        </v-list-item>
      </grid>
    </v-list>
  </v-card>
</template>

<script>
export default {
  components: {
    graph: () => import('@/components/graph'),
    Grid: () => import('@/components/grid.vue')
  },
  computed: {
    chartOptions () {
      return {
        chart: {
          type: 'line',
          stacked: false
        },
        stroke: {
          width: [0, 4]
        },
        labels: this.labels,
        xaxis: {
          type: 'datetime'
        },
        yaxis: {
          labels: {
            formatter: y => this.formatCurrency(y, this.account.currency)
          },
          min: 0
        },
        tooltip: {
          intersect: false,
          y: {
            formatter: y => this.formatCurrency(y, this.account.currency)
          }
        }
      }
    },
    labels () {
      let date = this.account?.date
      if (!date) { return null }

      date = this.moment(date)

      return this.account.days.map(d => {
        const dd = this.moment(date)
        dd.add(d, 'days')
        return dd.valueOf()
      })
    },
    series () {
      return [
        {
          name: this.$t('t.Sales'),
          type: 'area',
          data: this.account?.sales?.data ?? []
        },
        {
          name: this.$t('t.Payments'),
          type: 'line',
          data: this.account?.payments?.data ?? []
        }
      ]
    }
  },
  props: {
    account: Object
  }
}
</script>

<style lang="stylus" scoped>
.v-list
  display flex
  flex-direction row

.v-list-item
  display flex
  flex-direction column
  min-height auto
</style>
